<template>
  <div :style="$parent.$parent.mobileBackground" :class="{'has-text-white': $parent.$parent.hasMobileBackground }" ref="menu">

    <section v-if="$store.state.setup.description"
      class="home-menu-banner section p-3 is-size-7">
      {{ $store.state.setup.description }}
    </section>

    <transition-group appear @before-enter="beforeEnter" @enter="enter" @leave="leave" tag="section"
      class="section p-0 pt-5">
      <div v-for="(section, i) in sections" class="experience-section p-4 mb-5" :key="'cat' + i" :data-index="i"
        :style="{ '--section-color': section.color }">
        <div class="section-title is-size-5 mb-4">{{ section.title }}</div>
        <div
          class="experience-button my-2"
          :class="[{ 'is-checked': ExperienceCompleted(item.experience.id), 'is-low':item.space.hierarchy == 'low'}, 'blocktype_'+item.experience.component[0].blockType]"
          @click="EnterExperience(item.space.slug)"
          v-for="item in roomsInSection(section)" :key="item.space.id" :ref="item.space.slug">
          <div class="circle">
            <d-icon v-if="item.experience.category.icon" :pack="item.experience.category.icon.pack"
            :icon="item.experience.category.icon.name" size="is-small" />
          </div>
          <div class="experience-title py-2 px-3">{{ item.experience.title }}
          </div>
          <div class="space p-2 has-text-weight-bold" v-if="item.space.alphanumeric">{{ item.space.alphanumeric }}</div>
        </div>
      </div>
    </transition-group>
  </div>
</template>

<script>
import gsap from "gsap";

export default {
  components: {},
  props: {},
  data() {
    return {
      ShowDialogOverrideExperience: false,
      spaceEditing: null,
      selectedExperience: null,
      selectedPlaylist: this.$store.state.setup.playlist,
    };
  },
  methods: {
    EnterExperience(spaceSlug){
      this.$store.commit("user/setMenuOffsetTop",this.$parent.$refs["mainSection"].$el.scrollTop)
      this.$store.commit("StartLoading")
      this.$router.push(spaceSlug)
    },
    roomsInSection(section) {
      return this.playlist.filter((x) => x.space.section.id == section.id);
    },
    beforeEnter(el) {
      el.style.opacity = 0;
      el.style.transform = "translateY(-50px)";
    },
    enter(el, done) {
      gsap.to(el, {
        opacity: 1,
        y: 0,
        duration: 0.8,
        onComplete: done,
        delay: el.dataset.index * 0.2
      });
    },
    leave(el, done) {
      gsap.to(el, {
        opacity: 0,
        x: -20,
        duration: 0.05,
        onComplete: done,
      });
    },
    ExperienceCompleted(experienceId) {
      return this.completedExperencies.includes(experienceId);
    }
  },
  computed: {
    playlist() {
      return this.$store.getters.menuItems;
    },
    sections() {
      return this.$store.getters.menuSections;
    },
    completedExperencies() {
      return this.$store.state.user.achievements
        // .filter(x => x.score >= 100)
        .map(x => x.experience)
    }
  },
  async mounted() {
    //console.log("**** menu mounted", this.$store.state.routeFrom)

    this.$parent.$refs["mainSection"].$el.scrollTo(0,this.$store.state.user.menuOffsetTop)
    
    if(this.$store.state.routeFrom.path != "/"){
      this.$socket.client.emit(
        "player:JoinSpace",
        { spaceSlug: 'menu' },
        (response) => {
          if (response.status) {
            // next();
            console.log("Server allowed join")
          } else {
            console.warn("Server did not allow joining");
            // return false;
          }
        }
      );
    }
    
    await this.$store.dispatch("user/GetAchievements");
    this.$store.dispatch("user/NeedsToRegister")

  }
}
</script>